// hooks/use-order-display-state.js
import {useMemo} from 'react';
import {
  ORDER_STATE,
  ORDER_STATE_ICON,
  PAYUNI_PAYMENT_ICON,
  ORDER_TYPE,
} from '../dictionary';

export default function useOrderDisplayState({
  display_state,
  payment_type,
  payment_subtype,
  order_type,
  logistics,
  payment_status,
}) {
  return useMemo(() => {
    if (payment_status === 'failure') {
      return {
        title: '付款失敗',
        subtitle: '請再次點擊付款按鈕，完成付款流程。',
        icon: '/images/order-icon-failure.svg',
        step: 1,
      };
    }

    let status = {
      title: '訂單成立',
      subtitle: '',
      step: 0,
      icon: '',
    };

    status.title = ORDER_STATE[display_state] || '-';
    if (display_state === 'payment_waiting') {
      if (payment_type === 'payuni' || payment_type === 'neweb') {
        status.subtitle =
          PAYUNI_PAYMENT_ICON[payment_subtype]?.description || '-';
        status.icon = PAYUNI_PAYMENT_ICON[payment_subtype]?.icon || '';
      } else {
        status.subtitle = PAYUNI_PAYMENT_ICON[payment_type]?.description || '-';
        status.icon = PAYUNI_PAYMENT_ICON[payment_type]?.icon || '';
      }
    } else {
      status.subtitle = ORDER_STATE_ICON[display_state]?.subtitle || '-';
      status.icon = ORDER_STATE_ICON[display_state]?.icon || '';
    }

    if (['extra', 'credit'].includes(order_type)) {
      status.subtitle = '此訂單為' + ORDER_TYPE[order_type];
    }

    if (
      display_state === 'logistic_transit' &&
      logistics?.[0]?.logistics_type === 'self_pick'
    ) {
      status.title = '可自取';
      status.subtitle = '訂單已理貨完成，可聯繫客服預約到店取貨！';
    }

    let phase = display_state.split('_')[0];
    status.step =
      {
        payment: 1,
        review: 2,
        production: 3,
        logistic: 4,
        completed: 5,
      }[phase] || 0;

    return status;
  }, [
    display_state,
    payment_type,
    payment_subtype,
    order_type,
    logistics,
    payment_status,
  ]);
}
