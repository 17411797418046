import React, {useContext, useState, useMemo, useRef, useCallback} from 'react';
import {dateUtil} from '../../Utils';
import {Context} from '../../AppContext';
import {Button, Row} from '../../Widgets';
import VoidOrder, {metadata as voidMeta} from '../../Modals/VoidOrder';
import PeriodTerminateModal from '../../Modals/PeriodTerminate';
import ReturnAppModal, {
  metadata as returnAppMeta,
} from '../../Modals/ReturnApp';
import styled from 'styled-components';

export default function BottomSection({order, onUpdate, orderItems}) {
  const app = useContext(Context);
  const [returnApps, setReturnApps] = useState([]);
  const [openPeriodDialog, setOpenPeriodDialog] = useState(false);

  const hide = useMemo(() => {
    return (
      !order ||
      ['credit', 'extra', 'monthly'].includes(order.order_type) ||
      order.payment_type === 'monthly' ||
      order.payment_status !== 'success'
    );
  }, [order]);

  const allowed = useRef(
    returnApps.length === 0 &&
      !['refunded', 'voided'].includes(order.display_state) && // 已退款完成、已取消
      order.display_state === 'completed' &&
      new Date() <= dateUtil.offsetDay(order.complete_time, 3), // 今天在訂單完成時間起算三天內，才允許申請退款
  ).current;

  const disabled = !allowed;
  const returnApp = returnApps.length > 0 ? returnApps[0] : null;
  let orderItem = orderItems.length > 0 ? orderItems[0] : null;

  const getReturnApps = useCallback(async () => {
    try {
      let resp = await app.actions.getReturnApps({
        order: order.id,
      });
      setReturnApps(resp.results);
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, order]);

  if (!order) {
    return null;
  }
  if (order && order.voided) {
    return null;
  }

  return (
    <Row margin="0 0 30px 0">
      {order.order_type === 'period' && (
        <Button
          onClick={() => setOpenPeriodDialog(true)}
          style={{margin: '0 5px 5px 0'}}>
          取消訂閱制
        </Button>
      )}
      {order.payment_type !== 'monthly' && order.payment_status !== 'success' && (
        <CancelBtn
          onClick={() =>
            app.actions.setModal({
              content: <VoidOrder order={order} onUpdate={onUpdate} />,
              ...voidMeta,
            })
          }>
          取消訂單
        </CancelBtn>
      )}

      {!hide && (
        <VoidBtn
          disabled={disabled || returnApp}
          onClick={() => {
            app.actions.setModal({
              content: (
                <ReturnAppModal
                  onUpdate={getReturnApps}
                  orderItem={orderItem}
                />
              ),
              ...returnAppMeta,
            });
          }}>
          售後申請
        </VoidBtn>
      )}

      <div style={{flex: 1}} />

      <PeriodTerminateModal
        show={openPeriodDialog}
        order={order}
        closeDialog={() => setOpenPeriodDialog(false)}
        refresh={onUpdate}
      />
    </Row>
  );
}

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 40px;
  border-radius: 10px;
  background-color: #ccc;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0;
`;

const CancelBtn = styled(StyledButton)`
  margin: 0 5px 5px 0;
`;

const VoidBtn = styled(StyledButton)`
  margin: 0 5px 5px 0;
`;
