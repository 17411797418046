import React, {useContext, useMemo} from 'react';
import {Button, Text} from '../../Widgets';
import {message} from 'antd';
import {Context} from '../../AppContext';
import Tracking from '../../Tracking';
import styled from 'styled-components';
const appConfig = require('../../data.json');

export default function CheckoutButton({order}) {
  const app = useContext(Context);
  const {profile} = app.state;
  const cart = useMemo(() => {
    try {
      return JSON.parse(order.cart);
    } catch (error) {
      console.log(error);
      message.error('購物車資訊錯誤');
      return {};
    }
  }, [order]);

  const checkout = () => {
    if (
      order.payment_type === 'credits' &&
      profile.credits < cart.calculations.amount
    ) {
      return message.warning('您的點數餘額不足。');
    }

    app.actions.setLoading(true);

    const event_id =
      typeof crypto?.randomUUID === 'function'
        ? crypto.randomUUID()
        : new Date().toISOString();
    // Tracking.purchase(order, profile?.id, event_id);
    Tracking.checkout(order, cart, profile?.id, event_id);

    window.open(
      `${appConfig.endpoint.apiHost}/checkout/request/?order=${
        order.id
      }&token=${window.localStorage.getItem('token')}&event_id=${event_id}`,
      '_self',
    );
  };

  const generateCheckoutButtonText = () => {
    switch (order.payment_type) {
      case 'credits':
        return '點數扣點付款';
      case 'credit':
        return '信用卡付款';
      case 'line_pay':
        return 'LINE PAY 付款';
      case 'offline':
        return '轉款完成回填匯款資訊';
      default:
        return '前往付款';
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: 10,
      }}>
      {order.payment_status === 'code_generated' && (
        <Text size="sm" style={{marginRight: 10}}>
          已產生付款資訊，請見付款資訊的資料欄位
        </Text>
      )}

      {/* {order.payment_status !== 'success' &&
        order.payment_type === 'credits' &&
        profile.credits < cart.calculations.amount && (
          <Text size="sm" style={{marginRight: 10}}>
            您的點數餘額不足。
          </Text>
        )} */}

      {order.payment_type !== 'monthly' &&
        order.payment_type !== 'offline' &&
        order.payment_status !== 'success' &&
        // order.payment_status !== 'failure' &&
        order.payment_status !== 'refunded' &&
        !order.voided && (
          <StyledButton
            disabled={order.payment_status === 'code_generated'}
            onClick={checkout}>
            {generateCheckoutButtonText()}
          </StyledButton>
        )}
    </div>
  );
}

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 45px;
  border-radius: 10px;
  background-color: #505050;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  border: 1px solid transparent;
  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    width: 100%;
  }
`;
