import React, {useState} from 'react';
import {Input, Button} from '../Widgets';
import {message, Spin, DatePicker, Modal} from 'antd';
import formatValidator from '../Utils/formatValidator';
import {ErrOrder, errorHandler} from '../errors';
import moment from 'moment';
import styled from 'styled-components';
const appConfig = require('../data.json');

const {isNotEmpty} = formatValidator;

export default function PaymentOfflineModal({
  visible,
  values: _values,
  onCancel,
  onEdit,
}) {
  const [values, _setValues] = useState(
    {
      ..._values,
      remitted_time: _values.remitted_time || new Date(),
    } || {
      remittance_account: '',
      remittance_number: '',
      remitted_time: new Date(),
    },
  );
  const [loading, setLoading] = useState(false);

  function setValues(obj) {
    _setValues((prev) => ({...prev, ...obj}));
  }

  const valid = (values) => {
    if (
      !isNotEmpty(values.remittance_number) ||
      !/^\d{5}$/g.test(values.remittance_number)
    ) {
      throw new ErrOrder('請確認帳號為後五碼數字');
    }
  };

  const onConfirm = async () => {
    setLoading(true);
    try {
      valid(values);
      await onEdit(values);
      onCancel();
      message.success('填寫成功，等待工作人員對帳。');
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <StyledModal
        title="臨櫃匯款資訊"
        onCancel={onCancel}
        visible={visible}
        footer={<Footer onConfirm={onConfirm} onCancel={onCancel} />}
        width="540px">
        <h2
          style={{
            fontSize: '16px',
            fontWeight: 500,
            color: appConfig.colors.main,
            marginBottom: '24px',
          }}>
          轉款完成後回填以下資訊提供對帳
        </h2>
        <InputGroup>
          <h3>匯款戶名</h3>
          <Input
            value={values.remittance_account}
            onChange={(e) => setValues({remittance_account: e.target.value})}
          />
        </InputGroup>
        <InputGroup>
          <h3>匯款後五碼</h3>
          <Input
            value={values.remittance_number}
            onChange={(e) => setValues({remittance_number: e.target.value})}
          />
        </InputGroup>
        <InputGroup>
          <h3>匯款時間</h3>
          <DatePicker
            defaultValue={moment(values.remitted_time)}
            onChange={(value, dateString) => {
              setValues({remitted_time: dateString});
            }}
            format="YYYY-MM-DD HH:mm"
            showTime
            style={{
              width: 250,
              height: 40,
            }}
          />
        </InputGroup>
      </StyledModal>
    </Spin>
  );
}

const Footer = ({onConfirm, onCancel}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <StyledButton onClick={onCancel}>取消</StyledButton>
      <StyledButton className="confirm-button" onClick={onConfirm}>
        確認
      </StyledButton>
    </div>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 10px 30px;
    background-color: #fafafa;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      color: #5a5a5a;
      line-height: 1.58;
    }
  }
  .ant-modal-body {
    padding: 20px 30px;
  }
  .ant-modal-footer {
    padding: 15px 30px;
    border-top: none;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #fafafa;
  padding: 10px 0;
  background-color: #ccc;
  border: none;
  &.confirm-button {
    background-color: ${appConfig.colors.main};
    &:hover {
      color: #fafafa;
      opacity: 0.75;
    }
  }
`;

const InputGroup = styled.div`
  margin-bottom: 14px;
  h3 {
    font-size: 14px;
    color: #505050;
    margin-bottom: 8px;
  }
  > input {
    margin: 0;
    border-radius: 2px;
    border: solid 1px #ccc;
    padding: 10px 12px;
    font-size: 14px;
  }
`;
