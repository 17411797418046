import React from 'react';
import {Row, Text} from '../../Widgets';
import {EXRA_ORDER_TYPE} from '../../dictionary';
import {Link} from 'gatsby';
import Block from '../../Components/Checkout/Block';
import {RowText} from '../../Widgets';

export default function ExtraOrderSection({order}) {
  if (!order) {
    return null;
  }
  return (
    <Block title="補收款訂單資訊">
      <Row>
        <Link to={`/order?id=${order.base_order}`}>
          <Text size="sm" style={{textDecoration: 'underline'}}>
            原訂單資訊
          </Text>
        </Link>
      </Row>
      <RowText
        weight="400"
        label="補收類別"
        value={EXRA_ORDER_TYPE[order.extra_type]}
      />
      <RowText weight="400" label="補收原因" value={order.description} />
      <RowText weight="400" label="補收金額" value={order.amount} />
    </Block>
  );
}
