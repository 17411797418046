import React, {Fragment} from 'react';
import {Row, Text, Divider} from '../../Widgets';
import {dateUtil} from '../../Utils';
import {PAYMENT_STATUS} from '../../dictionary';
import {RowText} from '../../Widgets';
import Block from '../../Components/Checkout/Block';

const config = require('../../data.json');

const getPaymentTypeDisplay = (order) => {
  const mainType =
    {
      credits: '點數付款',
      neweb: '藍新付款',
      payuni: '統一付款',
      offline: '臨櫃匯款',
      monthly: '月結付款',
    }[order.payment_type] || '未知付款方式';

  if (order.payment_type === 'payuni') {
    const subType =
      {
        credit: '信用卡付款',
        cvs_cod: '超商取貨付款',
        cvs: '超商繳費',
        atm: 'ATM付款',
        web_atm: '網路ATM付款',
        barcode: '超商條碼繳費',
        line_pay: 'Line Pay 付款',
      }[order.payment_subtype] || '';

    return `${mainType} ${subType}`.trim();
  }

  return mainType;
};

const getPaymentStatus = (order) => {
  if (order.payment_type === 'monthly' && order.payment_status === 'success') {
    return order.monthly_order ? '已出帳' : '未出帳';
  }
  return PAYMENT_STATUS[order.payment_status];
};

export default function PaymentSection({order}) {
  if (!order) {
    return null;
  }

  const textColor = order.payment_status !== 'success' && config.colors.main;

  return (
    <Block title="付款資訊">
      <RowText
        label="付款方式"
        value={getPaymentTypeDisplay(order)}
        color={textColor}
      />

      {order.payment_status !== 'success' &&
        order.payment_status !== 'failure' &&
        order.code_no && (
          <Fragment>
            <RowText
              label={
                {
                  atm: '轉帳帳號：',
                  cvs: '超商繳費代碼：',
                }[order.payment_subtype]
              }
              value={
                (order.bank_code &&
                  ' 銀行代碼 ' + order.bank_code + ' - 帳號 ') + order.code_no
              }
              color={textColor}
            />
            <RowText
              label="繳費期限"
              value={dateUtil.format(order.pay_deadline, 'YYYY-MM-DD HH:mm')}
              color={textColor}
            />
          </Fragment>
        )}
      <RowText
        label="付款狀態"
        value={getPaymentStatus(order)}
        color={textColor}
      />
      {order.payment_type === 'offline' && (
        <Fragment>
          {config.information.offline_bank && (
            <Fragment>
              <RowText
                label="收款銀行"
                value={config.information.offline_bank}
                color={textColor}
              />
              <RowText
                label="收款帳號"
                value={config.information.offline_account}
                color={textColor}
              />
              <RowText
                label="收款戶名"
                value={config.information.offline_account_name}
                color={textColor}
              />
            </Fragment>
          )}
          <RowText
            label="匯款戶名"
            value={order.remittance_account}
            color={textColor}
          />
          <RowText
            label="匯款帳號後五碼"
            value={order.remittance_number}
            color={textColor}
          />
          <RowText
            label="匯款時間"
            value={dateUtil.format(order.remitted_time, 'YYYY-MM-DD HH:mm')}
            color={textColor}
          />
        </Fragment>
      )}
      <Divider />
      <Row>
        <Text size="sm">
          {order.payment_type === 'offline' ? (
            <div>
              匯出款項後，請務必以官方LINE訊息、臉書訊息或信箱告知以下資訊，以便會計進行款項確認。謝謝。
              <br />
              銀行轉帳手續費由客戶自行負擔，匯款金額請與訂單金額一致，勿自行扣除轉帳手續費，以免影響對帳及延誤出貨。
            </div>
          ) : (
            '詳細繳費通知可查收E-mail信箱【統一金流 PAYUNi】，付款完成後統一金流將發送收款通知信至您的E-mail信箱。'
          )}
        </Text>
      </Row>
    </Block>
  );
}
