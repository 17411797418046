import React from 'react';
import {Text} from '../../Widgets';
import Block from '../../Components/Checkout/Block';

export default function OrderNote({order}) {
  return (
    <Block title="訂單備註">
      <Text size="sm" color="#505050" style={{lineHeight: 1.71}}>
        {order.note ? order.note : '目前尚無備註'}
      </Text>
    </Block>
  );
}
