import React, {Fragment} from 'react';
import {Row, Text, Button, Expander, Divider, RowText} from '../../Widgets';
import useInvoiceData from '../../hooks/use-invoice-data';
import {
  LOVECODE_OPTIONS,
  INVOICE_TYPES,
  TWO_COPIES_TYPES,
} from '../../dictionary';
import Block from '../../Components/Checkout/Block';
const config = require('../../data.json');

const downloadUrl = 'https://inv.ezpay.com.tw/Invoice_index/search_platform';

export default function UserInfoSection({order}) {
  const {invoices, isCreated} = useInvoiceData({order});

  if (!order) {
    return null;
  }

  const InvoiceTitle = ({invoice}) => (
    <div>
      <RowText size="md" label="發票號碼" value={invoice.invoice_number} />
      <RowText
        weight="400"
        label="發票種類"
        value={INVOICE_TYPES[invoice.invoice_type]?.label}
      />
    </div>
  );

  return (
    <Block title="發票資訊">
      {invoices.map((invoice, index) => {
        const type = INVOICE_TYPES[invoice.invoice_type];
        const subtype = TWO_COPIES_TYPES[invoice.invoice_subtype];

        if (!type) {
          return (
            <Row key={index}>
              <Text>發票資料有誤</Text>
            </Row>
          );
        }

        return (
          <Expander
            key={invoice.invoice_number || index}
            title={<InvoiceTitle invoice={invoice} />}
            containerStyle={{
              padding: '16px 22px 14px',
              backgroundColor: '#fafafa',
              borderRadius: '6px 6px 0 0',
              marginBottom: 0,
              marginTop: index === 0 ? '0' : '16px',
            }}
            contentStyle={{
              backgroundColor: '#fafafa',
              borderRadius: '0 0 6px 6px',
              padding: '0 22px 16px',
            }}>
            <Divider margin="0 0 16px" color="#ccc" />
            <RowText
              label="發票狀態"
              value={isCreated ? '發票已開立' : '發票尚未開立'}
              weight="400"
            />
            {type.value === 'donate' ? (
              <div>
                <RowText
                  label="愛心碼"
                  value={invoice.love_code}
                  weight="400"
                />
                <RowText
                  label="捐贈單位名稱"
                  value={
                    LOVECODE_OPTIONS.find((x) => x.value === invoice.love_code)
                      ?.label || '-'
                  }
                  weight="400"
                />
              </div>
            ) : type.value === 'two_copies' && subtype ? (
              <div>
                <RowText label="載具類別" value={subtype.label} weight="400" />
                {invoice.invoice_subtype === 'mobile_vehicle' && (
                  <RowText
                    label={`${subtype.label}`}
                    value={invoice.mobile_vehicle_code}
                    color={config.colors.main}
                    weight="400"
                  />
                )}
                {invoice.invoice_subtype === 'citizen_personal_certificate' && (
                  <RowText
                    label={`${subtype.label}`}
                    value={invoice.citizen_personal_certificate_code}
                    color={config.colors.main}
                    weight="400"
                  />
                )}
              </div>
            ) : type.value === 'three_copies' ? (
              <div>
                <RowText
                  label="公司統編"
                  value={invoice.gui_number}
                  weight="400"
                />
                {!isCreated && (
                  <RowText
                    label="公司抬頭"
                    value={invoice.company_title}
                    weight="400"
                  />
                )}
              </div>
            ) : null}
            {isCreated && (
              <Fragment>
                <RowText
                  label="發票日期"
                  value={invoice.create_time}
                  weight="400"
                />
                <RowText
                  label="隨機碼"
                  value={invoice.random_num}
                  weight="400"
                />
                <Button href={downloadUrl} target="_blank">
                  下載發票證明聯
                </Button>
              </Fragment>
            )}
            {order.payment_type === 'monthly' && (
              <Row>
                <Text size="sm">
                  月結付款的訂單，統一在結算出帳日開立，月結對帳單中開立一份三聯式發票
                </Text>
              </Row>
            )}
          </Expander>
        );
      })}
    </Block>
  );
}
