import React from 'react';
import {Row, Text, Divider, Expander, RowText} from '../../Widgets';
import useLogisticData from '../../hooks/use-logistic-data';
import {
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_SELF,
  DELIVERY_TYPE,
  LOGISTIC_TRACKING_URL,
  STORE_CHANNEL,
} from '../../dictionary';
import Block from '../../Components/Checkout/Block';
import styled from 'styled-components';
const config = require('../../data.json');

export default function LogisticSection({order}) {
  const {logistics, isCreated} = useLogisticData({order});

  const LogisticTitle = ({logistic}) => {
    return (
      <div>
        <RowText
          size="md"
          label="系統物流編號"
          value={`# ${logistic.id ? logistic.id : ''}`}
        />
        <RowText
          label="收件方式"
          weight="400"
          value={DELIVERY_TYPE[logistic.logistics_type]}
        />
      </div>
    );
  };

  // const RowText = ({label, value, ...props}) => (
  //   <Row>
  //     <Text size="sm" color="#505050" {...props}>
  //       {label}
  //     </Text>
  //     <Text size="sm" color="#505050" {...props}>
  //       {value}
  //     </Text>
  //   </Row>
  // );

  return (
    <Block title="收件方式">
      {logistics.map((logistic, index) => (
        <Expander
          title={<LogisticTitle logistic={logistic} />}
          key={logistic.id || index}
          containerStyle={{
            padding: '16px 22px 14px',
            backgroundColor: '#fafafa',
            borderRadius: '6px 6px 0 0',
            marginBottom: 0,
            marginTop: index === 0 ? '0' : '16px',
          }}
          contentStyle={{
            backgroundColor: '#fafafa',
            borderRadius: '0 0 6px 6px',
            padding: '0 22px 16px',
          }}>
          <Divider margin="0 0 16px" color="#ccc" />
          <RowText
            label="物流狀態"
            weight="400"
            value={
              isCreated
                ? logistic.logistics_type === 'self_pick'
                  ? LOGISTIC_STATUS_SELF[logistic.logistics_status]
                  : LOGISTIC_STATUS[logistic.logistics_status]
                : '尚未有物流單（物流單將在付款完成後建立）'
            }
          />
          {isCreated && (
            <>
              {['hct', 'mailing', 'tcat', 'kerry_tj'].includes(
                logistic.logistics_type,
              ) && (
                <RowText
                  label="物流宅配單號"
                  weight="400"
                  value={logistic.tracking_number || '資料尚未產生'}
                  color={config.colors.main}
                />
              )}

              <RowText
                label={`預計${
                  logistic.logistics_type === 'self_pick' ? '可自取' : '出貨'
                }時間`}
                value={
                  (logistic.shipping_time &&
                    ` ${logistic.shipping_time.slice(0, 10)}`) ||
                  '資料尚未產生'
                }
                weight="400"
              />

              {logistic.receipt_time && (
                <RowText
                  label={`指定${
                    logistic.logistics_type === 'self_pick' ? '自取' : '收貨'
                  }時間`}
                  value={`${logistic.receipt_time.slice(
                    0,
                    10,
                  )} ${logistic.receipt_time.slice(11, 16)}`}
                  weight="400"
                />
              )}

              <RowText
                label="資料最後更新時間"
                value={
                  (logistic.updated &&
                    ` ${logistic.updated.slice(0, 10)} ${logistic.updated.slice(
                      11,
                      16,
                    )}`) ||
                  '資料尚未產生'
                }
                weight="400"
              />
              {LOGISTIC_TRACKING_URL[logistic.logistics_type] && (
                <LogisticTrackingButton
                  href={LOGISTIC_TRACKING_URL[logistic.logistics_type]}
                  target="_blank"
                  rel="noreferrer">
                  貨況進度查詢
                </LogisticTrackingButton>
              )}
            </>
          )}
          {['hct', 'mailing', 'tcat', 'kerry_tj', 'special_car'].includes(
            logistic.logistics_type,
          ) && (
            <>
              {logistic.is_delivery_private && (
                <>
                  <Divider />

                  <RowText label="保密代寄" value="是" weight="400" />
                  <RowText
                    label="寄件人"
                    value={logistic.sender_name}
                    weight="400"
                  />

                  <RowText
                    label="寄件人手機"
                    value={logistic.sender_phone}
                    weight="400"
                  />

                  <RowText
                    label="寄件人電話"
                    value={`${logistic.sender_tel}${
                      logistic.sender_tel_ext
                        ? ` # ${logistic.sender_tel_ext}`
                        : ''
                    }`}
                    weight="400"
                  />

                  <RowText
                    label="寄件人地址"
                    value={`${logistic.sender_zip} ${logistic.sender_city} ${logistic.sender_district} ${logistic.sender_address}`}
                    weight="400"
                  />
                </>
              )}

              <Divider />

              <RowText
                label="收件人"
                value={logistic.receiver_name}
                weight="400"
              />

              <RowText
                label="收件人手機"
                value={logistic.receiver_phone}
                weight="400"
              />

              <RowText
                label="收件人電話"
                value={`${logistic.receiver_tel}${
                  logistic.receiver_tel_ext
                    ? ` # ${logistic.receiver_tel_ext}`
                    : ''
                }`}
                weight="400"
              />

              <RowText
                label="收件人地址"
                value={`${logistic.zip_code} ${logistic.receiver_city} ${logistic.receiver_district} ${logistic.receiver_address}`}
                weight="400"
              />

              <RowText
                label="收件備註"
                value={logistic.logistics_note}
                color={config.colors.main}
                weight="400"
              />
            </>
          )}
          {['ezship', 'payuni', 'xdelivery'].includes(
            logistic.logistics_type,
          ) && (
            <>
              <Divider />
              {isCreated && (
                <RowText
                  label="店到店單號"
                  value={logistic.tracking_number}
                  weight="400">
                  <a
                    href={LOGISTIC_TRACKING_URL[logistic.logistics_type]}
                    style={{margin: '0 10px'}}
                    target="_blank"
                    rel="noreferrer">
                    查件連結
                  </a>
                </RowText>
              )}

              <RowText
                label="通路"
                value={STORE_CHANNEL[logistic.st_state]}
                weight="400"
              />
              <RowText
                label="門市名稱"
                value={logistic.rstore_name}
                weight="400"
              />
              <RowText
                label="門市地址"
                value={logistic.rstore_addr}
                weight="400"
              />
              <RowText
                label="收件人"
                value={logistic.receiver_name}
                weight="400"
              />

              <RowText
                label="收件人手機"
                value={logistic.receiver_phone}
                weight="400"
              />
            </>
          )}
        </Expander>
      ))}
    </Block>
  );
}

const LogisticTrackingButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 40px;
  background-color: ${config.colors.main};
  font-size: 14px;
  font-weight: 500;
  color: #fafafa;
  border-radius: 10px;
  &:hover {
    color: #fafafa;
  }
`;
