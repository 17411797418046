import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col, message} from 'antd';
// import GA from "../Utils/GA";
// import Pixel from "../Utils/fbPixel";
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Steps from '../Cart/Steps';
import {Heading} from '../../Widgets';
import TopSection from './TopSection';
import UserInfoSection from './UserInfoSection';
import PaymentSection from './PaymentSection';
import LogisticSection from './LogisticSection';
import InvoiceSection from './InvoiceSection';
import CreditOrderSection from './CreditOrderSection';
import ExtraOrderSection from './ExtraOrderSection';
import AfterSaleServiceSection from './AfterSaleServiceSection';
import PeriodSection from './PeriodSection';
import Calculation from '../../Components/Calculation';
import BottomSection from './BottomSection';
import CartItemTable from '../../Components/CartItemTable';
import Tracking from '../../Tracking';
import OrderNote from './OrderNote';
import {FixedBar} from '../../Widgets';
import {ExclamationCircleFill} from '@styled-icons/bootstrap';
import {Close} from '@styled-icons/material-rounded';
const queryString = require('query-string');
const appConfig = require('../../data.json');

const dbg = {
  id: '202101250311097264', //"202101270632376822"
};

export default function OrderDetail({location}) {
  const {id = dbg.id} = queryString.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [cart, setCart] = useState(null);
  const [status, setStatus] = useState(
    queryString.parse(location.search).status,
  );
  const app = useContext(Context);
  const {profile} = app.state;

  const getOrder = useCallback(async () => {
    let order = null;

    if (id && profile) {
      try {
        order = await app.actions.getOrder(id);
        setOrder(order);
        setCart(JSON.parse(order.cart));
      } catch (ex) {
        console.warn(ex);
      }
    }
    return order;
  }, [id, profile, app.actions]);

  const getOrderItems = useCallback(
    async (id) => {
      if (id && profile) {
        try {
          let resp = await app.actions.getOrderItems({
            order: id,
          });
          setOrderItems(resp);
        } catch (err) {
          console.warn(err);
        }
      }
    },
    [profile, app.actions],
  );

  const waitForOrderStatusUpdate = useCallback(async () => {
    const maxAttempts = 10;
    const delayBetweenAttempts = 1000;

    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      try {
        const updatedOrder = await getOrder();

        if (!updatedOrder) {
          return {status: 'error', message: '無法獲取訂單訊息'};
        }

        if (updatedOrder.display_state !== 'payment_waiting') {
          setOrder(updatedOrder);
          setCart(JSON.parse(updatedOrder.cart));
          await getOrderItems(updatedOrder.id);
          setStatus(null);
          return {status: 'success', message: ''};
        }

        await new Promise((resolve) =>
          setTimeout(resolve, delayBetweenAttempts),
        );
      } catch (error) {
        console.error('Error fetching order:', error);
        return {status: 'error', message: '獲取訂單時發生錯誤'};
      }
    }

    return {status: 'timeout', message: '請稍候重整或聯繫客服人員'};
  }, [getOrder, getOrderItems]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (status === 'success') {
        const result = await waitForOrderStatusUpdate();
        switch (result.status) {
          case 'success':
            break;
          case 'timeout':
            // message.warning(result.message);
            break;
          case 'error':
            // message.error(result.message + '，請稍候再重整或聯繫客服人員。');
            break;
          default:
            break;
        }
      } else {
        let _order = await getOrder();
        if (_order) {
          await getOrderItems(_order.id);
        }
      }
      setLoading(false);
    })();
  }, [getOrder, getOrderItems, waitForOrderStatusUpdate, status]);

  const stepMessages = [
    '付款提醒：請注意訂單繳費期限，下單後7天未付款系統自動取消訂單，原訂單優惠將不保留不退還，請注意您的權益！',
    '上傳檔案後才會進審稿流程，請先完成上傳檔案',
  ];

  const stepColors = ['#F8E6B8', '#F8E6B8'];

  if (loading) {
    return null;
  }

  if (status === 'success') {
    return null;
  }

  if (!profile) {
    return <UnAuthResult />;
  }

  if (!order) {
    return (
      <Fragment>
        <FixedBar bg={stepColors[order?.payment_status === 'success' ? 1 : 0]}>
          {stepMessages[order?.payment_status === 'success' ? 1 : 0]}
        </FixedBar>
        <ContentWrapper bg="#ededed" maxWidth="1080">
          <Heading align="center" color={appConfig.colors.main}>
            無此訂單
          </Heading>
        </ContentWrapper>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <FixedBar
        Icon={() => <ExclamationCircleFill size={20} color="#C68F34" />}
        bg={stepColors[order?.payment_status === 'success' ? 1 : 0]}
        color="#505050">
        {stepMessages[order?.payment_status === 'success' ? 1 : 0]}
      </FixedBar>
      <ContentWrapper bg="#ededed" maxWidth="1080">
        {['default', 'extra', 'monthly'].includes(order.order_type) && (
          <Steps step={order.payment_status === 'success' ? 3 : 2} />
        )}
        <TopSection
          key={order.payment_status}
          order={order}
          id={id}
          onUpdate={async () => {
            app.actions.setLoading(true);
            let _order = await getOrder();
            await getOrderItems(_order.id);
            app.actions.setLoading(false);
          }}
        />
        {order.order_type === 'credit' ? (
          <Fragment>
            {/* 點數訂單 */}
            <PaymentSection order={order} onUpdate={getOrder} />
            <CreditOrderSection order={order} cart={cart} />
            <InvoiceSection order={order} />
          </Fragment>
        ) : order.order_type === 'extra' ? (
          <Fragment>
            {/* 補收款訂單 */}
            <PaymentSection order={order} onUpdate={getOrder} />
            <ExtraOrderSection order={order} cart={cart} />
            <InvoiceSection order={order} />
          </Fragment>
        ) : (
          <Row gutter={[20, 0]}>
            {/* 購物車物品 */}
            <Col flex="100%">
              <CartItemTable
                viewOnly={true}
                isOrderPage={true}
                order={order}
                orderItems={orderItems}
                onUpdate={async () => {
                  app.actions.setLoading(true);
                  let _order = await getOrder();
                  await getOrderItems(_order.id);
                  app.actions.setLoading(false);
                }}
              />
            </Col>

            <Col flex="1">
              {/* 訂單資訊 */}
              <UserInfoSection order={order} cart={cart} />

              {/* 付款資訊 */}
              <PaymentSection order={order} onUpdate={getOrder} />

              {/* 運送方式與配送資訊 */}
              <LogisticSection order={order} />

              {/* 發票資訊 */}
              <InvoiceSection order={order} />

              {/* 訂單備註 */}
              <OrderNote order={order} />

              {/* 訂閱委託單資訊 */}
              <PeriodSection order={order} />

              {/* 售後服務申請 */}
              <AfterSaleServiceSection order={order} orderItems={orderItems} />

              {/* 防詐騙宣導 */}

              {/* <div style={{marginBottom: 30}}>
                提醒您目前常見之詐騙手法如下： 1.
                只要來電顯示開頭「＋」者，都是國際電話！來電顯示開頭「＋」者，是國際電話！「+886」是出自台灣的國際電話，小心辨認以防受騙！
                2.
                國內ATM自動提款機並沒有分期付款設定解除等服務，只要提及「ATM解除分期付款設定」，就一定是詐電話，切勿依來電指示操作自動提款機提至銀行提（匯）款或交付現金給任何人，以免被騙。
                3.{appConfig.information.name}
                網路平台無貨到付款服務，收取包裹前請確認查詢寄件人或公司等訂單資料，再判斷是否要領取包裹，以免受騙!瞭解更多反詐騙詳細內容請至「內政部警政署」165反詐騙網站。
              </div> */}
            </Col>
            <Col flex="400px">
              <Calculation calculations={cart.calculations} order={order} />
            </Col>
          </Row>
        )}
        <BottomSection
          order={order}
          orderItems={orderItems}
          onUpdate={async () => {
            app.actions.setLoading(true);
            await getOrder();
            app.actions.setLoading(false);
          }}
        />
      </ContentWrapper>
    </Fragment>
  );
}
