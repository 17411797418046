import React from 'react';
import {RowText} from '../../Widgets';
import Block from '../../Components/Checkout/Block';

export default function UserInfoSection({order, cart}) {
  if (!order) {
    return null;
  }

  let date = new Date(order.complete_time);
  let lastReturnDate = new Date(date.setDate(date.getDate() + 3)).toISOString();

  return (
    <Block title="訂購人資訊">
      <RowText weight="400" label="顧客名稱" value={cart.config.name} />
      <RowText weight="400" label="手機號碼" value={cart.config.phone} />
      <RowText weight="400" label="電子信箱" value={cart.config.email} />
      <RowText
        weight="400"
        label="通訊地址"
        value={`${cart.config.zip || cart.config.zip_code} ${
          cart.config.city
        } ${cart.config.district} ${cart.config.address}`}
      />

      {order.complete_time && (
        <RowText
          weight="400"
          label="訂單完成時間"
          value={order.complete_time.slice(0, 16).replace('T', ' ')}
        />
      )}
      {order.complete_time && (
        <RowText
          weight="400"
          label="可退款時間"
          value={`${lastReturnDate.slice(0, 16).replace('T', ' ')}前`}
        />
      )}
    </Block>
  );
}
