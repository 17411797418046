import React, {useContext, useState, Fragment, useRef} from 'react';
import {Context} from '../AppContext';
import {message} from 'antd';
import {Input, Button, Divider, Text, Row, Heading, TextArea} from '../Widgets';
import styled from 'styled-components';
const config = require('../data.json');

export default function RefundModal(props) {
  const {onUpdate} = props;
  const [record, _setRecord] = useState({
    ...props.refund,
    // status: "",
    // note: "",
    // amount: "",
    // order: null,
    // issuer: null,
    // order_owner: null,
    // created: null,
    // item_index: 0,
    // bank_account: "", //帳號
    // bank_account_name: "", //戶名
    // bank_code: "", //銀行代碼
    // bank_name: "", //分行
  });

  const app = useContext(Context);

  const edit = async () => {
    app.actions.setLoading(true);
    let {id, bank_account, bank_account_name, bank_code, bank_name} = record;

    try {
      if (!bank_account || !bank_account_name || !bank_code || !bank_name) {
        return message.warning('請填入所有的匯款資訊');
      }

      await app.actions.editRefund({
        id,
        bank_account,
        bank_account_name,
        bank_code,
        bank_name,
      });
      onUpdate();
      app.actions.setModal(null);
    } catch (err) {
      console.warn(err);
      message.warning('編輯退款資訊錯誤');
    }

    app.actions.setLoading(false);
  };

  function setRecord(obj) {
    _setRecord((prev) => ({...prev, ...obj}));
  }

  return (
    <div>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          *轉帳銀行代碼
        </Text>
        <Input
          value={record.bank_code}
          onChange={(e) => setRecord({bank_code: e.target.value})}
          placeholder="例：004 （台灣銀行）"
        />
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          *轉帳分行名稱
        </Text>
        <Input
          value={record.bank_name}
          onChange={(e) => setRecord({bank_name: e.target.value})}
          placeholder="例：板新分行"
        />
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          *轉帳戶名
        </Text>
        <Input
          value={record.bank_account_name}
          onChange={(e) => setRecord({bank_account_name: e.target.value})}
        />
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          *轉帳帳號
        </Text>
        <Input
          value={record.bank_account}
          onChange={(e) => setRecord({bank_account: e.target.value})}
        />
      </Row>
      {/* <Row>
        <Text size="sm" style={{marginRight: 10}}>
          備註
        </Text>
        <TextArea
          value={record.note}
          style={{marginRight: 10}}
          onChange={(e) => setRecord({note: e.target.value})}
        />
      </Row> */}

      <Divider paddingSize="lg" />

      <Row style={{justifyContent: 'flex-end', margin: '0'}}>
        <StyledButton
          onClick={() => app.actions.setModal()}
          style={{marginRight: 10}}>
          取消
        </StyledButton>
        <StyledButton onClick={() => edit()}>確定</StyledButton>
      </Row>
    </div>
  );
}

const metadata = {
  title: '退款申請單',
  width: '600px',
};

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 40px;
  border-radius: 10px;
  background-color: ${config.colors.main};
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0;
`;

export {metadata};
