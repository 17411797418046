import React, {useContext, useState} from 'react';
import {Context} from '../AppContext';
import {message, Result} from 'antd';
import {
  Button,
  Divider,
  Text,
  Row,
  TextArea,
  Select,
  SelectOption,
} from '../Widgets';
import {VOIDED_REASON} from '../dictionary';
import styled from 'styled-components';
const config = require('../data.json');

export default function VoidOrderModal(props) {
  const {order, onUpdate} = props;
  const [confirming, setConfirming] = useState(false);
  const [values, _setValues] = useState({
    void_reason: VOIDED_REASON[0],
    note: '',
  });

  const app = useContext(Context);

  const voided = async () => {
    app.actions.setLoading(true);
    let {void_reason, note} = values;

    if (void_reason === '其他原因') {
      void_reason = void_reason + ' > ' + note;
    }

    try {
      await app.actions.voidOrder({id: order.id, void_reason});
      app.actions.setModal(null);
      setConfirming(false);
      onUpdate();
    } catch (ex) {
      message('取消發生錯誤');
      console.error('void api error', ex);
    }
    app.actions.setLoading(false);
  };

  function setValues(obj) {
    _setValues((prev) => ({...prev, ...obj}));
  }

  return confirming ? (
    <Result
      status="warning"
      title="是否取消此訂單"
      subTitle="取消訂單後，無法復原"
      extra={[
        <Button key="cancel" onClick={() => setConfirming(false)}>
          取消
        </Button>,
        <Button
          key="ok"
          onClick={() => {
            voided();
          }}>
          確定
        </Button>,
      ]}
    />
  ) : (
    <div>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          原因
        </Text>

        <Select
          value={values.void_reason}
          onChange={(value) => setValues({void_reason: value})}
          style={{flexBasis: '250px'}}>
          {VOIDED_REASON.map((x, idx) => (
            <SelectOption key={idx} value={x} style={{width: 'auto'}}>
              {x}
            </SelectOption>
          ))}
        </Select>
      </Row>
      {values.void_reason === '其他原因' && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            其他原因
          </Text>
          <TextArea
            value={values.note}
            onChange={(e) => {
              setValues({note: e.target.value});
            }}
          />
        </Row>
      )}

      <Divider paddingSize="lg" />

      <Row style={{justifyContent: 'flex-end', margin: '0'}}>
        <StyledButton
          onClick={() => app.actions.setModal()}
          style={{marginRight: 10}}>
          取消
        </StyledButton>
        <StyledButton onClick={() => setConfirming(true)}>確定</StyledButton>
      </Row>
    </div>
  );
}

const metadata = {
  title: '取消訂單',
  width: '600px',
};

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 40px;
  border-radius: 10px;
  background-color: ${config.colors.main};
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0;
`;

export {metadata};
