import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  OrderBlock,
  Row,
  Text,
  Button,
  Divider,
  Link,
  RowText,
} from '../../Widgets';
import Block from '../../Components/Checkout/Block';
import {Context} from '../../AppContext';
import {RETURN_STATUS, REFUND_TYPE, REFUND_STATUS} from '../../dictionary';
import {Alert} from 'antd';
import RefundModal, {metadata as refundMeta} from '../../Modals/Refund';
import {dateUtil} from '../../Utils';
import styled from 'styled-components';
const config = require('../../data.json');

export default function AfterSaleServiceSection({order}) {
  const [returnApps, setReturnApps] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const app = useContext(Context);
  const hide = useMemo(() => {
    return (
      !order ||
      ['credit', 'extra', 'monthly'].includes(order.order_type) ||
      order.payment_type === 'monthly' ||
      order.payment_status !== 'success'
    );
  }, [order]);

  const allowed = useRef(
    returnApps.length === 0 && // 只能退款一次
      // order.payment_status === 'success' && //付款成功
      // !(
      //   order.display_state === 'completed' &&
      //   new Date() > dateUtil.offsetDay(order.complete_time, 3)
      // ) && // ~ 訂單完成三日內
      order.display_state === 'refunding' && // 退款中
      new Date() <= dateUtil.offsetDay(order.complete_time, 3), // 今天在訂單完成時間起算三天內，才允許申請退款
  ).current;
  const disabled = !allowed;

  const refund = refunds.length > 0 ? refunds[0] : null;
  const returnApp = returnApps.length > 0 ? returnApps[0] : null;

  const getReturnApps = useCallback(async () => {
    try {
      let resp = await app.actions.getReturnApps({
        order: order.id,
      });
      setReturnApps(resp.results);
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, order]);

  const getRefunds = useCallback(async () => {
    try {
      let resp = await app.actions.getRefunds({
        order: order.id,
      });
      setRefunds(resp.results);
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, order]);

  useEffect(() => {
    if (!hide) {
      getReturnApps();
      getRefunds();
    }
  }, [getReturnApps, getRefunds, hide]);

  if (hide) {
    return null;
  }

  if (order.order_type === 'period') {
    return (
      <OrderBlock title="退款申請">
        <Row>
          <Text size="sm">
            訂閱制訂單，當期已扣款後無法退款，如欲取消訂閱方案請於每期扣款前7個工作天來電或來信由客服辦理。
            如您訂閱的商品有折扣優惠，卻於期數未滿時取消訂閱，則需要補支付優惠差額。
          </Text>
        </Row>
      </OrderBlock>
    );
  }

  return (
    <Block title="售後申請單狀態">
      {!returnApp && <Text size="sm">尚無售後服務申請紀錄</Text>}
      {returnApp && (
        <Fragment>
          <RowText
            label="退款申請單狀態"
            weight="400"
            value={RETURN_STATUS[returnApp.status]}
          />
          <RowText
            label="申請原因"
            weight="400"
            value={returnApp.rejected_reason}
          />
          <RowText label="備註" weight="400" value={returnApp.note} />
          <RowText
            label="瑕疵商品附圖"
            weight="400"
            value={
              <Link to={encodeURI(returnApp.file)} target="_blank">
                {returnApp.file}
              </Link>
            }
          />
        </Fragment>
      )}

      {refund && (
        <Fragment>
          <Divider />
          <RowText
            label="退款單狀態"
            weight="400"
            value={REFUND_STATUS[refund.status]}
          />
          <RowText
            label="處理方式"
            weight="400"
            value={
              order.payment_type === 'credits'
                ? {
                    partial: '部分點數退還',
                    transfer_full: '全額點數退還',
                    full: '全額點數退還',
                  }[refund.refund_type]
                : REFUND_TYPE[refund.refund_type]
            }
          />
          <RowText label="備註" weight="400" value={refund.note} />
          <RowText label="退款總額" weight="400" value={`$ ${refund.amount}`} />
          {['partial', 'transfer_full'].includes(refund.refund_type) &&
            order.payment_type !== 'credits' && (
              <Fragment>
                {refund.status === 'waiting' && (
                  <Row>
                    <StyledButton
                      disabled={disabled}
                      onClick={() => {
                        app.actions.setModal({
                          content: (
                            <RefundModal
                              onUpdate={getRefunds}
                              refund={refund}
                            />
                          ),
                          ...refundMeta,
                        });
                      }}
                      style={{marginRight: 10}}>
                      填寫退款資訊
                    </StyledButton>
                    <Alert
                      message="若處理方式為「轉帳退款」請填入匯款資訊"
                      type="info"
                      style={{marginLeft: 10}}
                    />
                  </Row>
                )}
                <RowText
                  label="匯款銀行代碼"
                  weight="400"
                  value={refund.bank_code}
                />
                <RowText
                  label="匯款分行"
                  weight="400"
                  value={refund.bank_name}
                />
                <RowText
                  label="匯款戶名"
                  weight="400"
                  value={refund.bank_account_name}
                />
                <RowText
                  label="匯款帳號"
                  weight="400"
                  value={refund.bank_account}
                />
              </Fragment>
            )}
        </Fragment>
      )}
    </Block>
  );
}

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 40px;
  border-radius: 10px;
  background-color: ${config.colors.main};
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  padding: 0;
`;
